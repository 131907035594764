export const EXPERIENCE = [
    {
        id:6,
        company: "AMD Inc.",
        designation: "Senior Software Designer",
        duration: "2020 - current",
        desc: "Working with the Java team to optimize Java performance on AMD's EPYC server processors.",
    },
    {
        id:5,
        company: "Experitest",
        designation: "Engineer | Seetest",
        duration: "2018 - 2020",
        desc: "Worked with the Seetest Android team to develop new features and maintain \
        existing features for cloud based Android devices in Seetest platform. \
        Development and execution of POCs, and competitive analysis for the new \
        prospects.",
    },
    {
        id:4,
        company: "Soccer.ai (not registered)",
        designation: "Co-Founder",
        duration: "2017 - 2018",
        desc: "Worked on developing a CNN based image annotation tool to detect players,\
        ball and different areas of the football pitch in order to get quantitative data for the football players.\
        Extended the classifier for videos. We couldn’t get enough real-world video data in order to create a robust\
        classifier and had to stop the startup due to a lack of financial fundings.",
    },

    {
        id:3,
        company: "Qualcomm",
        designation: "Engineer | Linux Performance",
        duration: "2015 - 2017",
        desc: "Responsible for investigation of SW architecture involving analysis of important performance use-cases to find novel performance optimisation solutions.\
         UX, boot time and system performance analysis of Qualcomm's own and competitors' chipsets.\
          Responsible for SW performance execution activities on Qualcomm's wearable SOC product lines.",
    },
    {
        id:2,
        company: "Qualcomm",
        designation: "Software Engineering Intern",
        duration: "May 2014 - July 2014",
        desc: "Developed static and dynamic memory analysis tools in Android to generate specific workloads,\
        collect critical memory info., and create real-time graphs, memory maps, and visual reports for memory performance analysis.",
    },

    {
        id:1,
        company: "Qualcomm",
        designation: "Software Engineering Intern",
        duration: "May 2013 - July 2013",
        desc: "Designed and developed a PCM data logging system for linux audio driver using \
        Relayfs to dump data from audio driver to user-space. Integrated this system in an Android app.",
    },

    {
        id:0,
        company: "IronCode Gaming Pvt. Ltd",
        designation: "Game Developement Intern",
        duration: "May 2012 - July 2012",
        desc: "Studied IronCode's game development framework based on \textbf{DirectX-9} and developed two games, AirHawks and SmashOut using the same.",
    },
]

export const EDUCATION = [

    {
        id: 2,
        institution: "Indian Institute of Technology Roorkee | Roorkee Uttarakhand",
        degree: "Integrated Dual Degree (B.Tech and M.Tech) in Computer Science and Engineering",
        duration: "June 2015",
        percentage: "8.6",
    },

    {
        id: 1,
        institution: "Mayur Public School | Delhi",
        degree: "Senior Secondary",
        duration: "March 2010",
        percentage: "94 percent."
    },

    {
        id: 0,
        institution: "Mayur Public School | Delhi",
        degree: "High School",
        duration: "March 2008",
        percentage: "96 percent."
    }
]

export const COURSES = {
    graduate: "Adv. Algo, Adv. OS, Adv. Architechture, Adv. DBMS",
    undergraduate: "Algorithms, DS, OS, Compilers, Discrete Maths, Coumputer Networks",
    independent: "ML by Andrew Ng, Deep Learning Specialization by Andrew Ng, Applied ML in Python, Adv. Algorithms (Princeton)",
}

export const LANGUAGES = [
    "Java",
    "Python",
    "C++",
    "Javascript",
    "C",
    "Shell",
    "HTML and CSS",
    "C#,"
]

export const AOI = [
    "Application Development",
    "System Design",
    "Machine Learning and AI",
    "Game Development",
    "Performance Modelling",
    "BigData and Cloud Computing",
    "Shift Left Testing",
];